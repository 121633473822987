<template>
  <div class="page-container">
    <x-table
      :config="config"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      @add="add"
      @search="search"
      @page-change="pageChange"
    >
    </x-table>
    <Modal
      v-model="modal.show"
      :title="modal.title"
      :width="900"
      @on-visible-change="modalShow"
    >
      <div style="max-height: calc(100vh - 250px); overflow: auto">
        <Form
          ref="form"
          :model="modal.model"
          :label-width="85"
          :rules="modal.rules"
        >
          <div
            style="
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin-bottom: 24px;
            "
          >
            <FormItem
              :show-message="false"
              style="margin-bottom: 0px"
              prop="logo"
              :label-width="0"
            >
              <upload-image
                :max="1"
                :clear-flag="!modal.show"
                v-model="modal.model.logo"
              >
              </upload-image>
            </FormItem>
            <span style="font-size: 16px; font-weight: bold; padding-top: 6px"
              >Logo</span
            >
          </div>
          <Row>
            <Col span="11">
              <FormItem prop="account" label="登陆账号">
                <Input v-model="modal.model.account"></Input>
              </FormItem>
            </Col>
            <Col span="11" offset="1">
              <FormItem prop="role" label="账号类型">
                <Select v-model="modal.model.role" multiple>
                  <Option
                    v-for="item in companyType"
                    :value="item.roleId"
                    :key="item.id"
                    >{{ item.name }}</Option
                  >
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem prop="name" label="公司名称">
                <Input v-model="modal.model.name"></Input>
              </FormItem>
            </Col>
            <Col span="11" offset="1">
              <FormItem prop="shortName" label="公司简称">
                <Input v-model="modal.model.shortName"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem prop="linkName" label="联系人">
                <Input v-model="modal.model.linkName"></Input>
              </FormItem>
            </Col>
            <Col span="11" offset="1">
              <FormItem prop="tel" label="联系电话">
                <Input v-model="modal.model.tel"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="11">
              <FormItem prop="address" label="地址">
                <Input v-model="modal.model.address"></Input>
              </FormItem>
            </Col>
            <Col span="11" offset="1">
              <FormItem prop="email" label="邮箱">
                <Input v-model="modal.model.email"></Input>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span="23">
              <FormItem prop="remark" label="简介">
                <Input type="textarea" v-model="modal.model.remark"></Input>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </div>
      <div slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.loading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      table: {
        columns: [
          {
            title: "公司名称",
            minWidth: 100,
            key: "name",
          },
          {
            title: "公司LOGO",
            minWidth: 100,
            render: (h, { row }) => {
              return <img src={row.logo} style="width: 40px; height: 40px" />;
            },
          },
          {
            title: "联系人",
            minWidth: 100,
            key: "linkName",
          },
          {
            title: "联系人电话",
            minWidth: 130,
            key: "tel",
          },
          {
            title: "公司地址",
            minWidth: 100,
            key: "address",
          },
          {
            title: "电子邮件",
            minWidth: 100,
            key: "email",
          },
          {
            title: "操作",
            render: (h, { row }) => {
              return (
                <div>
                  <a style="margin-right: 10px" onClick={() => this.edit(row)}>
                    编辑
                  </a>
                  <Poptip
                    confirm
                    transfer
                    title="确定删除吗?"
                    on-on-ok={() => this.del(row.id)}
                  >
                    <a>删除</a>
                  </Poptip>
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      modal: {
        show: false,
        title: "",
        loading: false,
        model: {
          id:'',
          account: "",
          name: "",
          shortName: "",
          category: "",
          logo: "",
          address: "",
          email: "",
          linkName: "",
          tel: "",
          remark: "",
          role: [],
        },
        rules: {
          logo: [
            {
              required: true,
              message: "请上传公司logo",
            },
          ],
          account: [
            {
              required: true,
              message: "请填写登陆账号",
            },
          ],
          name: [
            {
              required: true,
              message: "请填写公司名称",
            },
          ],
          tel: [
            {
              required: true,
              message: "请填写公司电话",
            },
          ],
          linkName: [
            {
              required: true,
              message: "请填写联系人",
            },
          ],
          role: [
            {
              required: true,
              type: "array",
              message: "请选择公司类型",
            },
          ],
        },
      },
      config: {
        filter: {
          add: {
            addBtnName: "新增公司",
            ca: "company_list_add",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "公司名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
          ],
        },
        page: {
          pageNo: 1,
          pageSize: 10,
          total: 0,
        },
      },
      companyType: [],
      search_data: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
  methods: {
    add() {
      this.modal.title = "新增公司";
      this.modal.show = true;
    },
    pageChange(pageNo) {
      this.config.page.pageNo = pageNo;
      this.getList();
    },
    search(data) {
      this.search_data = data;
      this.config.page.pageNo = 1;
      this.getList();
    },
    getList() {
      this.table.loading = true;
      this.$post(this.$api.company.LIST, {
        pageNo: this.config.page.pageNo,
        pageSize: this.config.page.pageSize,
        category: +this.user.category + 1,
        ...this.search_data,
      })
        .then((res) => {
          console.log(res);
          this.table.data = res.list;
          this.config.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    getCompanyType() {
      this.$post(this.$api.company.typeList, {
        pageNo: 1,
        pageSize: 99,
      }).then((res) => {
        this.companyType = res.list;
      });
    },
    edit(row) {
      for (let key in this.modal.model) {
        this.modal.model[key] = row[key];
      }
      // this.modal.model.id = row.id;
      this.modal.model.role = row.roleid.split(",").map((item) => Number(item));
      this.modal.show = true;
      this.modal.title = "编辑公司";
    },
    del(id) {
      this.$post(this.$api.company.DELETE, {
        id
      }).then(() => {
        this.$Message.success('删除成功')
        this.getList()
      })
    },
    modalShow(visible) {
      if (visible) return;
      this.modal.model = {
        id:'',
        account: "",
        name: "",
        shortName: "",
        category: "",
        logo: "",
        address: "",
        email: "",
        linkName: "",
        tel: "",
        remark: "",
        role: [],
      };
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.modal.model };
        params.category = +this.user.category + 1;
        params.roleId = this.modal.model.role.join(",");
        params.logo = this.modal.model.logo.replace(
          /http:\/\/img.xd.sidwit.com\//g,
          ""
        );
        delete params.role;
        let url = this.modal.model.id?this.$api.company.EDIT:this.$api.company.add
        this.modal.loading = true;
        this.$post(url, params)
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.loading = false;
          });
      });
    },
  },
  mounted() {
    this.getList();
    this.getCompanyType();
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>